.projectsContainer 
{
    position: relative;
    flex-basis: 100%;
    overflow-x: clip;
    overflow-y: scroll;
}

.noscroll
{
    overflow-y: hidden;
}

.projectsContainer::-webkit-scrollbar
{
    width: 0;
}

.projects
{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(max(33%, 18rem), 1fr));
    max-width: max-content;
    margin: auto auto;
    padding: 2%;
}

/* PROJECT */

.project
{
    margin: 1.5rem;
    position: relative;
    box-shadow: 0 0 2rem black;
    border-radius: .5rem;
    transition: all 200ms;
    transition-timing-function: cubic-bezier(0.2, 0.6, 0.8, 1.5);
    animation-name: animateIn;
    animation-duration: .5s;
    animation-fill-mode: backwards;
}

.project:hover
{
    transform: scale(1.03);
    box-shadow: 0 0 2rem .5rem black;
    cursor: pointer;
}

.project:hover > .overlay
{
    background-color: var(--color-project-hover);
}

.project > .overlay
{
    z-index: 1;
    top: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    text-align: center;
    border-radius: .5rem;
    transition: background-color 200ms;
    transition-timing-function: ease-in;
}

.project > img
{
    position: relative;
    width: 100%;
    object-fit: cover;
    border-radius: .5rem;
    display: block;
}

.titlebar
{
    pointer-events: none;
    bottom: -1px;
    width: 100%;
    position: absolute;
    background-color: var(--color-transparent-dark);
    text-align: center;
    border-radius: 0 0 .4rem .4rem;
}

.titlebar h2
{
    font-size: 1rem;
    line-height: 1.5rem;
}

/* SELECTED PROJECT */

.selectedProject
{           
    border-radius: .5rem;
    position: fixed;
    z-index: 1;
}

.selectedProject > img
{    
    width: 100%;
    height: 100%;
    vertical-align: bottom;
    object-fit: cover;
    border-radius: .5rem;
}

.selectedProject > video
{    
    width: 100%;
    height: 100%;
    vertical-align: bottom;
    object-fit: cover;
    border-radius: .5rem;
    z-index: 500;
    overflow-y: scroll;
}


/* PROJECT DESCRIPTION*/

.descriptionWrapper
{
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
}


.projectDescriptionContainer
{
    z-index: 2;
    background-color: var(--color-transparent-dark);
    position: fixed;
    width: min(60rem, 50%);
    clip-path: polygon(10% 0, 100% 0%, 100% 100%, 0% 100%);
    overflow-y: scroll;
    transition: width 200ms;
    height: 100%;
    display: flex;
}

.projectDescriptionContainer::-webkit-scrollbar
{
    width: 0;
}

.projectDescriptionContent
{
    margin: auto;
    position: relative;
    padding: 10%;
    padding-left: 25%;
}

.projectDescriptionContent > h1
{
    position: relative;
    left: -1.5rem;
    font-size: 2.5rem;
    color: white;
}

.projectDescriptionContent > p
{
    line-height: 2rem;
    font-size: 1.25rem;
    color: var(--color-text);
}

.verticalDescriptionButton
{
    display: none;
    position: fixed;
    z-index: 3;
    bottom: -1px;
    background-image: linear-gradient(
        to top, 
        hsl(0, 0%, 0%) 0%,
        hsla(0, 0%, 0%, 0.989) 15.7%,
        hsla(0, 0%, 0%, 0.957) 29.4%,
        hsla(0, 0%, 0%, 0.908) 41.2%,
        hsla(0, 0%, 0%, 0.843) 51.3%,
        hsla(0, 0%, 0%, 0.767) 59.9%,
        hsla(0, 0%, 0%, 0.683) 67.2%,
        hsla(0, 0%, 0%, 0.592) 73.2%,
        hsla(0, 0%, 0%, 0.498) 78.3%,
        hsla(0, 0%, 0%, 0.404) 82.5%,
        hsla(0, 0%, 0%, 0.313) 86.1%,
        hsla(0, 0%, 0%, 0.227) 89.1%,
        hsla(0, 0%, 0%, 0.15) 91.9%,
        hsla(0, 0%, 0%, 0.085) 94.5%,
        hsla(0, 0%, 0%, 0.034) 97.2%,
        hsla(0, 0%, 0%, 0) 100%
    );
    padding: .5rem;
    width: 100%;
    height: 6rem;
    align-items: flex-end;
    justify-content: center;
}

.verticalDescriptionButton .content
{
    display: flex;
    justify-content: center;
    position: relative;    
    animation-name: animateIn;
    animation-duration: .5s;
    animation-fill-mode: backwards;
}

.verticalDescriptionButton svg
{
    width: min(30rem, 85%);
    position: relative;
    height: 4rem;
    filter: drop-shadow( 0px 0px .5rem rgba(255, 255, 255, 1));
    stroke: var(--color-title);
    transition: transform 50ms;
}

.verticalDescriptionButton .content h2
{
    position: absolute;
    bottom: 0;
    font-size: 1.125rem;    
    text-shadow: 0 0 1rem white;
    transition: bottom 50ms;
}


/* Bullet Buttons */
.bulletButtonContainer
{
    position: fixed;
    margin-top: 1rem;
    right: 1rem;
    display: flex;
    flex-direction: column;
    z-index: 3;
    user-select: none;  
}

.bulletButtonContainer .animationWrapper
{
    position: relative;
    animation-name: animateInFromRight;
    animation-duration: .5s;
    animation-delay: .3s;
    animation-fill-mode: backwards;
}

.bulletButton
{
    padding: .5rem;
    background-color: var(--color-bullet-button);
    border-radius: 50%;
    margin-bottom: .5rem;
    height: 2rem;
    width: 2rem;
}

.bulletButton > img
{
    height: 100%;
    width: 100%;
}

.bulletButton:hover
{
    cursor: pointer;
}



@media (max-width: 1000px)
{
    .projectDescriptionContainer
    {
        clip-path: none;
        width: 100%;
    }

    .projectDescriptionContent
    {
        padding-left: 15%;
    }
}

@media (max-width: 1000px) and (orientation: portrait)
{
    .hideButton
    {
        display: none;
    }

    .verticalDescriptionButton
    {
        display: flex;
    }

    .projectDescriptionContent
    {
        padding-bottom: calc(10% + 2rem);
    }

    .bulletButtonContainer .animationWrapper
    {
        animation-name: animateIn;
        animation-delay: 0s;
    }
}