.notFound
{
    color: var(--color-input-field);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.notFound h1
{
    font-size: 12rem;
    margin: 0;
    line-height: 10rem;
}

.notFound p
{
    position: relative;
    font-weight: bold;
    font-size: 2rem;
    margin: 0;
}