.aboutContainer
{
    display: flex;
    justify-content: center;
    overflow-y: scroll;
    padding: 2%;
}

.aboutContainer::-webkit-scrollbar
{
    width: 0;
}

.about
{    
    margin: 1.5rem;
    display: flex;
    height: fit-content;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    width: min(100%, 76rem);
}

.skills
{
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
    gap: 2rem;
    width: 100%;
}


.skill
{
    flex-grow: 1;
    min-width: 20rem;
    display: flex;
    border-radius: 1rem;
    padding: 1rem;
    box-shadow: 0 0 2rem black;
    border: 1px solid var(--color-text);
    background-color: var(--color-input-field);    
    position: relative;
    animation-name: animateIn;
    animation-duration: .5s;
    animation-fill-mode: backwards;
}

.skill h2
{
    color: var(--color-title);
    font-size: 2rem;
}

.skill h3
{
    margin: 0;
}

.skill h2, .skill h3
{
    margin-right: 1rem;
}

.skill h3
{
    color: var(--color-text);
    line-height: 0;
    font-size: 1.5rem;
}

.skill > img
{
    margin-right: 1rem;
    width: 9rem;
}


.otherSkillsContainer
{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 1px solid var(--color-text);
    border-radius: 1rem;
    background-color: var(--color-input-field);
    position: relative;
    animation-name: animateIn;
    animation-duration: .5s;
    animation-fill-mode: backwards;
}

.otherSkills
{
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
    gap: 2.5rem;    
}

.smallSkill
{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5rem;
    position: relative;
    animation-name: animateIn;
    animation-duration: .5s;
    animation-fill-mode: backwards;
}

.smallSkill > img
{
    width: 5rem;
}

.experience
{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;    
    position: relative;
    animation-name: animateIn;
    animation-duration: .5s;
    animation-fill-mode: backwards;
}

.experience > div
{
    flex: 1 1 48%;
}

.experience h2
{
    margin-top: 2rem;
    margin-left: 2rem;
    font-size: 2rem;
}

.experience p
{
    margin: 0;
    font-size: 1rem;
    font-weight: 300;
}

.jobExperience, .education
{
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    background-color: var(--color-input-field);
    border: 1px solid var(--color-text);
    box-shadow: 0 0 2rem black;
}

.experience .timelineContent
{
    margin: 1rem;
    position: relative;
}

.experience svg
{
    position: absolute;
    height: 100%;
    overflow: visible;
    width: 3rem;
}

.experience .textContent
{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.experience .textContent > div
{
    padding-left: 3rem;
    padding-bottom: 2rem;
    position: relative;
    opacity: 0;
    left: -1rem;
    transition: all 200ms;
    transition-timing-function: ease-out;
}


.experience .sectionTitle,
.experience .sectionSubtitle
{
    margin: 0;
    display: inline-block;
}

.titleBlock .sectionTitle
{
    font-size: 1.5rem;
    color: var(--color-highlight);
}

.projectBlock .sectionTitle
{
    line-height: 2rem;
    font-size: 1.25rem;
    color: var(--color-title);
}

.experience .titleBlock .separator::after
{
    content: "-";
    margin-inline: .5rem;
    color: var(--color-title);
    visibility: visible;
}

.experience .titleBlock .noSeperator::after
{
    content: "-";
    margin-inline: .5rem;
    color: var(--color-title);
    visibility: hidden;
}

.experience .sectionSubtitle
{    
    color: var(--color-title);
}

.experience .duration
{
    font-family: "roboto";
    margin: 0;
    font-size: .875rem;
    color: var(--color-text);
}

svg circle,
svg rect
{
    transform-box: fill-box;
    transform-origin: center;
    transition: transform 300ms;
    transition-timing-function: cubic-bezier(0.1, 0.5, 0, 2);
}

svg circle
{
    transform: scale(0);
}

svg rect
{    
    transform: translate(-50%) rotate(45deg) scale(0);
}


@media (max-width: 550px)
{
    .skill > img
    {
        margin-right: 1rem;
        width: 6rem;
    }
    
    .skill
    {
        min-width: 8rem;
    }

    .smallSkill > img
    {
        width: 3.5rem;
    }

    .smallSkill h3
    {
        display: none;
    }

    .experience .sectionSubtitle
    {
        margin: 0;
        display: block;
    }
}