.contact
{
    overflow-y: auto;
    width: min(100%, 70rem);
    margin-inline: auto;

    position: relative;
    animation-name: animateIn;
    animation-duration: .5s;
    animation-fill-mode: backwards;
}

.contact h1
{
    font-size: 3rem;
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.contact h2
{
    font-size: 1.125rem;
}

.contactFormContainer
{
    padding: 2rem;
}

.contactFormContainer > .text
{
    padding-bottom: 1rem;
    text-align: center;
    font-size: larger;
}

.text > p
{
    color: var(--color-text)
}

.contactForm
{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.contactForm .formItem
{
    display: flex;
    justify-content: space-between;
}

.formItem > label
{
    flex-shrink: 0;
}

.formItem > span
{
    min-height: 6rem;
}

.contactForm > button
{
    background-color: var(--color-title);
    height: 3rem;
    border: none;
    border-radius: 2rem;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 200ms;
    width: 20%;
    align-self: flex-end;
    margin-top: 1rem;
}

.contactForm > button:hover
{
    background-color: var(--color-highlight);
}

.formItem > span, input
{
    font-size: 1rem;
    font-family: "roboto";
    width: 80%;
    border-radius: 2rem;
    padding: 1rem;
    outline: none;
    background-color: var(--color-input-field);
    color: var(--color-text);
    border: none;
    resize: vertical;
}

.formItem > span:focus, input:focus
{
    outline: 1px solid var(--color-highlight);
}

.formItem textarea
{
    display: none;
}

.formItem span:empty:after
{
    content: "Your message";
}


.formItem span:empty:after, 
.formItem input::placeholder
{
    text-transform: lowercase;
    font-family: "roboto";
    color: #777777;
    font-weight: 300;
}

.contactFormContainer label
{
    padding-right: 1rem;
}

@media (max-width: 670px)
{
    .contactForm > .formItem
    {
        width: 100%;
        flex-direction: column;
    }

    .formItem > span, input
    {
        width: auto;
        padding: 1rem;
    }

    .contactForm > button
    {
        width: 100%;
    }
}

.contactThanks
{
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: min(20rem, 100%);
    flex-direction: column;
}

.contactThanks > p
{
    text-align: center;
    font-size: larger;
    margin: 0;
}

.contactThanks > h1
{
    text-align: center;
    font-size: 2rem;
}

.contactThanks > img
{
    margin-bottom: -2rem;
    width: 100%;
    border-radius: 5%;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  color : var(--color-text) !important;
  caret-color: var(--color-text);
  -webkit-text-fill-color: var(--color-text) !important;
  -webkit-box-shadow: 0 0 0 1000px var(--color-input-field) inset !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
}