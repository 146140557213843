.header
{
  position: relative;
  background-color: black;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 0px 2rem black;
  z-index: 3;
  width: 100%;
}

.title, .headerElement
{
  padding-inline: 2rem;
  display: flex;
  align-items: center;
  position: relative;
}

.header .homeButton
{
  text-decoration: none;
  cursor: pointer;
}

.headerElements
{
  display: flex;
}

.headerElement
{  
  padding-inline: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
}

.headerElement:visited
{  
  color: inherit;
}

.headerElement > h2
{
  z-index: 4;
}

.headerElement > h2.active
{
  color: var(--color-highlight);
}

.headerElement > .overlay
{
  position: absolute;
  width: 100%;
  height: 0%;
  background-color: var(--color-highlight2);
  left: 0;
  bottom: 0;
  transition: height 50ms;
}

.headerElement:hover
{
  cursor: pointer;
}

.headerElement:hover > .overlay
{
  height: 100%;
}

.header h1, h2
{
  line-height: .8;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.header h1
{
  font-size: 1.5rem;
}

.header h2
{
  font-size: 1.125rem;
}

.hamburgerButton
{
  display: none;
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  padding-right: 1rem;
}

.hamburgerButton > img
{ 
  height: 100%;
}

@media (max-width: 780px)
{
    .header
    {
      flex-direction: column;
    }

    .headerElement
    {
      position: relative;
      max-height: 0;
      overflow: hidden;
      transition: max-height .2s;
    }

    .headerElement.visible
    {
      max-height: 500px;
    }

    .headerElement > h2
    {
      padding-left: 1rem;
    }

    .hamburgerButton
    {
      display: flex;
    }
}