@font-face {
  font-family: 'Roboto';
  font-weight: 300;
  src: url('../public/media/fonts/roboto-light-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url('../public/media/fonts/roboto-regular-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url('../public/media/fonts/roboto-medium-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url('../public/media/fonts/roboto-bold-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  src: url('../public/media/fonts/roboto-black-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto-Mono';
  font-weight: 300;
  src: url('../public/media/fonts/robotomono-light-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto-Mono';
  font-weight: 400;
  src: url('../public/media/fonts/robotomono-regular-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto-Mono';
  font-weight: 700;
  src: url('../public/media/fonts/robotomono-bold-webfont.woff2') format('woff2');
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
