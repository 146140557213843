:root
{
  --color-background: rgb(25, 25, 25);
  --color-input-field: rgb(35, 35, 35);
  --color-bullet-button: rgba(255, 255, 255, 0.2);
  --color-project-hover: rgba(255, 255, 255, 0.05);
  --color-transparent-dark: rgba(0, 0, 0, 0.9);
  --color-transparent-semi-dark: rgba(0, 0, 0, 0.7);
  --color-title: rgb(235, 235, 235);
  --color-text: rgb(210, 210, 210);
  --color-highlight: rgb(225, 150, 0);
  --color-highlight2: rgba(50, 50, 80, 0.8);
}

body
{
  background-color: var(--color-background);
  color: var(--color-title);  
  overflow-y: hidden;
}

h1, h2, h3
{
  font-family: 'Roboto-Mono', sans-serif;
}

.App
{
  display: flex;
  flex-direction: column;
  height: max(100dvh);
}

a:-webkit-any-link, 
a:any-link
{
  color: var(--color-text);  
  cursor: auto;
}

a:-moz-any-link
{
  color: var(--color-text);  
  cursor: auto;
}

a:link
{
  color: var(--color-text);  
  cursor: auto;
}

a:visited
{
  color: var(--color-text);  
  cursor: auto;
}

@keyframes animateIn {
  from {opacity: 0; bottom: -2rem;}
  to {opacity: 1; bottom: 0rem;}
}

@keyframes animateInFromRight {
  from {opacity: 0; right: -100%;}
  to {opacity: 1; right: 0rem;}
}