.landingContainer
{
    width: 100%;
    flex-basis: 100%;
    overflow-x: hidden;
    pointer-events: none;
}

.landing
{
    position: relative;
    height: 100%;
}

.landing .intro
{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: opacity .2s;
    z-index: 2;
    pointer-events: all;
}

.intro .textWrapper
{
    display: flex;
    justify-content: center;
    position: relative;
    height: fit-content;
    top: -10%;
    width: 40rem;
}

.intro .textContent
{
    display: flex;
    flex-direction: column;
    text-align: center;    
    position: relative;
    animation-name: animateIn;
    animation-duration: .5s;
    animation-fill-mode: backwards;
    transition: opacity 250ms;
}

.landing .intro p
{
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0;
}

.landing .intro h2
{
    font-size: 3rem;
}

.landing .overlay
{
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: var(--color-transparent-semi-dark);
    transition: background-color 250ms;
    z-index: 1;
}

.pageLinks
{
    overflow-y: clip;
    display: flex;
    width: 105%;
    min-height: 20rem;
    height: 100%;
}

.pageLink
{
    position: relative;
    left: -5%;
    height: 100%;
    flex-basis: 100%;
}

.pageLink .content
{
    position: absolute;
    width: 115%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.pageLinks .textContent
{
    display: flex;
    width: 40%;
    justify-content: center;
    position: relative;
    text-align: center;
    top: 35%;
    z-index: 2;
}

.pageLinks .animationWrapper
{
    position: relative;
    animation-name: animateIn;
    animation-duration: .5s;
    animation-fill-mode: backwards;
}

.pageLinks .imageWrapper
{
    position: relative;
    height: 100%;
    clip-path: polygon(15% 0%, 115% 0%, 100% 100%, 0% 100%);
}

.pageLink .highlight
{
    position: absolute;
    height: 100%;
    width: 115%;
    top: 0;
    transition: background-color 200ms;
    z-index: 4;
}

.pageLink img:hover ~ .highlight
{
    background-color: rgba(255, 255, 255, 0.3);
}

.landing p
{
    font-size: 1.5rem;
    font-weight: 300;
    margin: 0;
    color: var(--color-text);
    text-shadow: 0 0 1rem white;
}

.landing h2
{
    position: relative;
    font-size: 3.5rem;
    color: var(--color-title);
    text-shadow: 0 0 2rem rgba(255, 255, 255, 0.5);
}

.pageLink img
{
    position: relative;
    width: 115%;
    height: 100%;
    object-fit: cover;
    pointer-events: all;
    cursor: pointer;
}

@media (max-width: 1000px)
{
    .pageLinks
    {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: stretch;
    }

    .pageLinks .imageWrapper
    {
        clip-path: none;
    }

    .pageLink
    {
        left: 0;
    }

    .pageLink img
    {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .pageLink p
    {
        font-size: 1rem;
    }

    .pageLink h2
    {
        font-size: 1.5rem;
    }

    .pageLinks,
    .pageLink .highlight,
    .pageLink .content,
    .pageLinks .textContent
    {
        width: 100%;
    }

    .landing .intro .textWrapper
    {
        width: 90%;
    }

    .pageLink .textContent
    {
        top: 0;
        height: 100%;
        align-items: center;
    }
}